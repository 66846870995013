/* .credits {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

	background-color: black;

    font-family: 'OswaldBold';
    color: white;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 1.2em;

    transition: opacity 1s;
    margin: 0;
	padding: 10px;

	width: calc(100%-20px);
} */

.credits {
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: rgba(230, 230, 230, 1);

    font-family: 'OswaldBold';
    color: rgba(20, 20, 20, 1);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 1.2em;
    text-align: center;

    transition: opacity 1s;
    margin: 0;
}

.credits-logo {
    height: 10%;
    width: 80%;
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
}

.credits-logoImg {
    width: 20%;
    height: 100%;
    margin: 2.5%;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}