.landingPage {
    position: absolute;
    z-index: 10000;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-image: url("../../style/assets/language/background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    font-family: 'OswaldBold';
    color: white;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 1.2em;

    transition: opacity 1s;
    margin: 0;
}

.landingPage-container {
    height: 100%;
    width: 100%;

    padding: 5% 0 5% 0;

    text-align: center;

    overflow: auto;
}

.landingPage-header {
    height: 15%;
    font-weight: 600;
    letter-spacing: .2rem;
    font-size: clamp(1.2em, 5vw, 3em);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-bottom: 0.4em;
}

.landingPage-play {
    height: 30%;
    font-weight: 600;
    letter-spacing: .2rem;
    font-size: 1.2em;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-bottom: 0.2em;
}

.landingPage-playButton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 100%;
    aspect-ratio: 1/1;
    background-image: url('../../style/assets/landingPage/circleShadow.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.landingPage-playButtonGif {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 87%;
    height: 87%;
    background-position: center;
    background-size: contain;

    border-radius: 50%;
}

.landingPage-playButtonIcon {
    z-index: 20000;
    width: 50%;
    aspect-ratio: 1/1;

    background-image: url('../../style/assets/landingPage/playIcon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.landingPage-textContainer {
    width: 100%;
    min-height: 55%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-bottom: 0.2em;

    mix-blend-mode: hard-light;
}

.fade {
    --maskHeight: 60%;
    mask-image: linear-gradient(180deg, #000 var(--maskHeight), transparent);
}

.landingPage-text {
    width: 80%;
    min-height: 55%;

    margin-top: 1em;

    font-size: clamp(0.8em, 5vw, 2em);

    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
}

.landingPage-textLine {
    text-align: center;
    width: 100%;

    margin-bottom: 1em;
}

.landingPage-textScroll {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    height: 5%;
    aspect-ratio: 1/1;
    background-image: url('../../style/assets/landingPage/downArrow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    filter: invert(90%);

    transition: opacity 0.5s;
}

.landingPage-textLineBlank {
    height: 1em;
}