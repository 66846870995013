.language {
    position: absolute;
    z-index: 10000;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-image: url("../../style/assets/language/background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    font-family: 'OswaldBold';
    color: white;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 1.2em;

    transition: opacity 1s;
    margin: 0;
}

.language-container {
    height: 100%;
    width: 100%;

    padding: 5% 0 5% 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    overflow: hidden;
}

.language-part {
    width: 90%;
    height: 10%;

    text-align: center;
}

.language-titleLine {
    font-size: clamp(1em, min(7vw, 6vh), 5em);
    text-shadow: 0% 5% 1% rgba(0, 0, 0, 0.8);

    overflow: hidden;
}

.language-enterButton {
    font-size: clamp(1em, min(5vw, 5vh), 2.4em);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.language-enter {
    padding: 2% 4%;

    border-radius: 20%/50%;

    background-color: rgba(0, 0, 0, 0.5);
}

.language-langSelectionContainer {
    display: block;
    width: clamp(200px, 50%, 900px);
}

.language-selectArrow {
    position: relative;
    z-index: 50000;
    width: 1.8em;
    height: 1.8em;
    content: "";

    right: 0%;

    background: url("../../style/assets/landingPage/downArrow.png") 96% / 15% no-repeat;
    background-size: contain;
    background-position: center;

    filter: invert(100%);
}


.language-langSelection {
    height: clamp(2em, 60%, 8em);
    background-color: rgba(255, 255, 255, 0.1);

    border-radius: 10%/50%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.language-langSelection__control {
    position: relative !important;
    width: 100% !important;

    border: 0px solid rgba(255, 255, 255, 0) !important;
    box-sizing: border-box !important;
    border-radius: 100px !important;

    background-color: rgba(0, 0, 0, 0) !important;

    text-align: left !important;

    color: white !important;

    cursor: pointer !important;
}

.language-langSelection__indicator-separator {
    display: none !important;
}

.language-langSelection__value-container {
    left: 8px;
}

.language-langSelection__indicators {
    width: 2.4em;
}

.language-langSelection__indicator {
    position: absolute;
}

.language-langSelection__menu {
    background-color: #111827 !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.language-langSelection__option {
    background-color: rgba(0, 0, 0, 0) !important;
    color: black !important;
}

.language-langOption {
    display: flex;
    flex-direction: row;

    font-size: clamp(0.8em, 2vw, 0.8em);

    color: black;

    justify-content: flex-start;
    align-items: center;
}

.language-langFlag {
    min-width: 2em;
    height: clamp(2em, 100%, 30em);
    aspect-ratio: 1/1;

    margin-right: 5%;
}

.language-langLang {
    margin: 0 0 0 2%;

    font-size: clamp(0.8em, 2vw, 5em);

    color: white;
}